export const moduleConst = {
    namespaced: true,
    state: {
      const: {
        manageDomain: process.env.VUE_APP_API_MANAGE_DOMAIN,
        uploadDomain: process.env.VUE_APP_API_UPLOAD_DOMAIN,
        crawlerDomain: process.env.VUE_APP_API_CRAWLER_DOMAIN,
        produceDomain: process.env.VUE_APP_API_PRODUCE_DOMAIN,
        headers:  {
              'Content-Type': 'application/json',
              'Accept-Charset': 'utf-8',
              'Accept': 'application/json'
        },
        changeOperate: {
            "新增":"insert" ,
            "修改":"update" ,
            "刪除":"delete" ,
        },
        uploadResultOptions:{
            "upload":[{ text: '未选择', value: null }, '成功', '失败'],
        },
        downloadResultOptions:{
            "crawler":[{ text: '未选择', value: null }, '未下载', '成功', '失败'],
        },
        searchResultOptions:{
            "crawler":[{ text: '未选择', value: null }, '未发现文章', '已发现文章且继续搜寻', '已发现文章且完成搜寻','永久停止搜寻'],
        },
      },
    },
}