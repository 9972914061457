<template>
    <base-table
        :columns="columns"
        :api_path="api_path"
        :table_height="table_height"
    />
</template>

<script>
import BaseTable from "@/components/BaseTable.vue";

export default {
  components: {
    'base-table':BaseTable,
  },
  data () {
    return {
      api_path: "/human-processing/lottery/info",
      table_height: 300,
      columns: [
                {
                  field: 'lottery',
                  title: '乐透',
                },
                {
                  field: 'lottery_year',
                  title: '乐透年度',
                },
                {
                  field: 'lottery_number',
                  title: '乐透期数(要开奖的下一期)',
                },
                {
                  field: 'lottery_update_time',
                  title: '乐透期数的更新时间',
                },
                {
                  field: 'server_name_list',
                  title: '对应的下游网站',
                },
      ],
    }
  },
}
</script>

