<template>
    <div>
        <bread-crumb :props_items="items"/>
        <br>
        <h1>「乐透资讯」</h1>
        <lottery-query-table />
        <h1>「下游网站资讯」</h1>
        <server-query-table />
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import LotteryQueryTable from "./components/LotteryQueryTable.vue"
import ServerQueryTable from "./components/ServerQueryTable.vue"
export default {
    components: {
        'bread-crumb':Breadcrumb,
        'lottery-query-table':LotteryQueryTable,
        'server-query-table':ServerQueryTable,
    },
    data() {
      return {
        items: [
          {
            text: '文章管理后台(首页)',
             active: true
          },
        ]
      }
    }
}
</script>

