import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/main/home/HomeView.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
        import('../views/user/login/LoginView.vue')
  },
  {
    path: '/i-want-to-register',
    name: 'register',
    component: () =>
        import('../views/user/register/RegisterView.vue')
  },
  {
    path: '/upload-query',
    name: 'upload-query',
    component: () =>
        import('../views/upload/query/QueryView.vue')
  },
  // {
  //   path: '/upload-query-and-operate',
  //   name: 'upload-query-and-operate',
  //   component: () =>
  //       import('../views/upload/query-and-operate/QueryAndOperateView.vue')
  // },
  {
    path: '/upload-replace-api-server-domain',
    name: 'upload-replace-api-server-domain',
    component: () =>
        import('../views/upload/domain/replace-apiserver-domain/ReplaceApiServerDomainView.vue')
  },
  {
    path: '/crawler-query',
    name: 'crawler-query',
    component: () =>
        import('../views/crawler/query/QueryView.vue')
  },
  {
    path: '/crawler-query-type',
    name: 'crawler-query-type',
    component: () =>
        import('@/views/crawler/query-type/QueryTypeView.vue')
  },
  {
    path: '/crawler-query-and-operate-type',
    name: 'crawler-query-and-operate-type',
    component: () =>
        import('../views/crawler/query-and-operate-type/QueryAndOperateTypeView.vue')
  },
  {
    path: '/crawler-change-type',
    name: 'crawler-change-type',
    component: () =>
        import('../views/crawler/change-type/ChangeArticleTypeView.vue')
  },

  {
    path: '/crawler-replace-api-server-domain',
    name: 'crawler-replace-api-server-domain',
    component: () =>
        import('../views/crawler/domain/replace-apiserver-domain/ReplaceApiServerDomainView.vue')
  },
  {
    path: '/crawler-ad-filter',
    name: 'crawler-ad-filter',
    component: () =>
        import('../views/crawler/ad/filter/ChangeAdFilterView.vue')
  },
   {
    path: '/crawler-old-article',
    name: 'crawler-old-article',
    component: () =>
        import('../views/crawler/query-old-articles/QueryOldArticlesView.vue')
  },
  {
    path: '/human-processing-change-lottery',
    name: 'human-processing-change-lottery',
    component: () =>
        import( '../views/human-processing/change-lottery/ChangeLotteryView.vue')
  },

  {
    path: '/in-development',
    name: 'in-development',
    component: () =>
        import('../views/main/InDevelopment.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test'){
  router.beforeEach((to, from, next) => {
    if (!store.state.authenticate.isAuthenticated && to.name !== 'login' && to.name !== 'register'){
    //沒有登入且不是登入頁面且不是註冊頁面
     next({ name: 'login' })
    }
    else {
      next()
    }
  })
}


router.afterEach(() => {
  document.title = '文章管理后台';
  store.commit("form/resetFormData");
  store.commit("crudOperate/resetOperate");
});


export default router
