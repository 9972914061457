<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
<!--        primary, success, info, warning, danger, dark, light-->
      <b-navbar-brand><router-link to="/">[文章管理后台]</router-link></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="[上传后台]" right>
              <b-dropdown-item disabled>资料查询</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-query">[上传后台查询]</router-link></b-dropdown-item>
              <b-dropdown-divider />

              <b-dropdown-item disabled>网域相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/upload-replace-api-server-domain">更换[下游网站]网域</router-link></b-dropdown-item>

          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="[采集后台]" right>
              <b-dropdown-item disabled>资料查询</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-query">[采集后台查询]</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-query-type">[采集后台查询类型]</router-link></b-dropdown-item>
              <b-dropdown-divider />

              <b-dropdown-item disabled>采集相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-query-and-operate-type">永久停止[文章类型搜寻]</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-query-and-operate-type">重新将[文章类型搜寻]</router-link></b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-change-type">新增[文章类型]</router-link></b-dropdown-item>

              <b-dropdown-divider />



              <b-dropdown-item disabled>网域相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-replace-api-server-domain">更换[采集网站]网域</router-link></b-dropdown-item>

              <b-dropdown-item disabled>广告相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-ad-filter">更换[广告]过滤器</router-link></b-dropdown-item>

              <b-dropdown-item disabled>旧文章相关</b-dropdown-item>
              <b-dropdown-item ><router-link to="/crawler-old-article">不分期旧文章查询</router-link></b-dropdown-item>


          </b-nav-item-dropdown>
            <b-nav-item-dropdown text="[人工管理]" right>
                <b-dropdown-item ><router-link to="/human-processing-change-lottery">更换乐透</router-link></b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              <em>帐号</em>
            </template>
            <b-dropdown-item v-if="!isAuthenticated"><router-link to="/login">登入</router-link></b-dropdown-item>
            <b-dropdown-item v-if="isAuthenticated"  @click="logout">登出</b-dropdown-item>
<!--            <b-dropdown-item ><router-link to="/i-want-to-register">注册</router-link></b-dropdown-item>-->
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import {LogOutApiMixin} from "@/mixins/api/user/logOut";

export default {
  mixins: [LogOutApiMixin],
  computed: {
    isAuthenticated() {
        return this.$store.state.authenticate.isAuthenticated;
    },
    isDevelopmentOrRoot() {
        // return  this.$store.state.authorization.level === 'root';
        return process.env.NODE_ENV === 'development' || this.$store.state.authorization.level === 'root';
    },
  },
  methods: {
    async logout() {
      await this.logOutApi()
      this.$store.commit("authenticate/setAuthenticated", false);
      this.$store.commit("authorization/setAuthorization", "");
      this.$router.push('/login'); // 跳转到登录页面
    },
  },
};
</script>


