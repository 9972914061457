import {createNamespacedHelpers} from "vuex";
const { mapState:mapStateConst} = createNamespacedHelpers('const')
export const GetDomainMixin = {
    methods: {
         getDomain(domainStr) {
            if (domainStr === "manage" || domainStr === undefined) {
                return  this.const.manageDomain
            } else if (domainStr === "make") {
                return this.const.makeDomain
            } else if (domainStr === "upload") {
                return this.const.uploadDomain
            } else if (domainStr === "crawler") {
                return this.const.crawlerDomain
            }
            else {
                return null
            }
        },
    },
    computed: {
    ...mapStateConst({
        'const': state => state.const,
    }),
    },
};
